body {
    margin: 0;
    font-family: 'Russo One', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 15px;
}

code, pre {
    font-family: 'JetBrains Mono', Consolas, 'Courier New', monospace;
}

#navbar {
    border-radius: 0 0 15px 15px;
    padding: 15px 50px 20px;
}

.header-title {
    font-family: 'Russo One', sans-serif;
    font-size: 3em;
    margin-left: 0.53em;
    vertical-align: center;
    line-height: 1.7;
    color: #9BF316;
}

.visualizer-container {
    padding: 10px;
    background: linear-gradient(180deg, #0E0238 0%, #231069 100%);
    border-radius: 15px;
    margin-top: 15px;
}
.visualizer-controls {
    text-align: right;
    margin-top: 5px;
}

.detail-container {
    margin-top: 15px;
}

.zrok-datatable {
    margin-top: 30px;
}

.zrok-big-sparkline {
    margin-top: 30px;
    padding: 50px;
}

.btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    height: 25px;
    width: 25px;
    border: 0;
}

.rdt_TableRow {
    font-family: 'JetBrains Mono', Consolas, 'Courier New', monospace;
}

.nav-tabs {
    margin-top: 25px;
    margin-bottom: 25px;
}

.fullscreen {
    background: linear-gradient(180deg, #0E0238 0%, #231069 100%);
    padding: 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.fullscreen .row {
    text-align: center;
    justify-content: center;
}

.fullscreen h1 {
    font-size: 64pt;
}

.fullscreen-body {
    margin-top: 6em;
}

.fullscreen form {
    margin: 15px;
}

.fullscreen form input {
    margin: 15px 0;
    width: 400px;
}

.fullscreen a {
    color: #bbb;
}

.fullscreen pre {
    color: #bbb;
}

.errorMessage {
    color: deeppink;
}

.actions-tab {
    margin-top: 50px;
}
.actions-tab p {
    font-family: 'JetBrains Mono', Consolas, 'Courier New', monospace;
}

#zrok-reset-password {
    margin-top: 25px;
}

#zrok-tou {
    margin-top: 15px;
}

#controls-row {
    margin-left: -30px;
    margin-right: -30px;
}

#navbar {
    background: linear-gradient(180deg, #0E0238 0%, #231069 100%);
}